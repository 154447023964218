import './TieDyeSidebar.css';

export default function TieDyeSidebar(props: {logo: string}) {
    return (
        <div className="tie-dye-sidebar">
            <div className="logo-area">
                <img src="whitebread.svg" className="topbar-logo" alt="logo" id="bl"/>
                <img src={props.logo} alt="logo" width="90%"/>
            </div>
        </div>
    )
}