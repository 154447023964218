import React from 'react';
import './App.css';
import PowerPoint from './PowerPoint';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Control from './Control';
import Screensaver from './Screensaver';
import BBScreensaver from './BBScreensaver';
import PowerPointNew from './PowerPointNew';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<PowerPoint />} />
                    <Route path="/2" element={<PowerPointNew />} />
                    <Route path="/control" element={<Control />} />
                    <Route path="/bbscreensaver" element={<BBScreensaver imgsrc="rwl.svg" width={400} fullscreen />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
